import React from 'react';
import ListSectionSectionComponent from './ListSectionSectionComponent';
import ListFieldComponent from './ListFieldComponent';
import SingleLayoutComponent from './shared/SingleLayoutComponent';
import {FormSection} from '../model/FormSection';
import SectionEditorUpdate from './editors/SectionEditorUpdate';
import {SectionProvider} from '../contexts/SectionContext';
import ListSectionConditionalRuleComponent from './ListSectionConditionalRuleComponent';

interface Props {
  section: FormSection;
}

export default function SingleSectionComponent({section}: Props) {
  const editor = <SectionEditorUpdate section={section} />;
  let label = section.section_name ?? section.section_id;
  if (section.section_type !== 'basic') {
    label += ` [${section.section_type}]`;
  }
  return (
    <SectionProvider section={section}>
      <SingleLayoutComponent label={label} editor={editor} disabled={section.disabled}>
        <ListSectionSectionComponent sectionId={section.section_id} />
        <ListFieldComponent sectionId={section.section_id} />
        <ListSectionConditionalRuleComponent sectionId={section.section_id} />
      </SingleLayoutComponent>
    </SectionProvider>
  );
}
