import React from 'react';
import SingleLayoutComponent from './shared/SingleLayoutComponent';
import ConditionalRuleEditorUpdate from './editors/ConditionalRuleEditorUpdate';
import {FormConditionalRule} from '../model/FormConditionalRule';
import {ConditionalRuleProvider} from '../contexts/ConditionalRuleContext';
import {useSharedEntitiesContext} from '../contexts/SharedEntitiesContext';

interface Props {
  rule: FormConditionalRule;
}

export default function SingleConditionalRuleComponent({rule}: Props) {
  const editor = <ConditionalRuleEditorUpdate rule={rule} />;
  const {roles} = useSharedEntitiesContext();
  let label = rule.rule_type;
  if (rule.role_id) {
    const roleName = roles?.find((r) => r.role_id === rule.role_id)?.role_name;
    label += ` [${roleName ?? rule.role_id}]`;
  } else {
    label += ` [default]`;
  }
  return (
    <ConditionalRuleProvider rule={rule}>
      <SingleLayoutComponent label={label} editor={editor} disabled={rule.disabled} />
    </ConditionalRuleProvider>
  );
}
