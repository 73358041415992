import React from 'react';
import {ConditionalRulesProvider, useConditionalRulesContext} from '../contexts/ConditionalRulesContext';
import ListLayoutComponent from './shared/ListLayoutComponent';
import ConditionalRuleEditorCreate from './editors/ConditionalRuleEditorCreate';
import SingleConditionalRuleComponent from './SingleConditionalRuleComponent';

interface Props {
  fieldId: string;
}

function InternalConditionalRulesComponent({fieldId}: Props) {
  const {rules, loading} = useConditionalRulesContext();
  const editor = <ConditionalRuleEditorCreate partial={{resource_id: fieldId, resource_type: 'field'}} />;
  const children = rules?.map((rule) => <SingleConditionalRuleComponent rule={rule} key={rule.rule_id} />);
  return <ListLayoutComponent title={'Conditional Rules'} children={children} editor={editor} loading={loading} />;
}

export default function ListFieldConditionalRuleComponent({fieldId}: Props) {
  return (
    <ConditionalRulesProvider resourceId={fieldId}>
      <InternalConditionalRulesComponent fieldId={fieldId} />
    </ConditionalRulesProvider>
  );
}
