import {createContext, PropsWithChildren, useContext} from 'react';
import {FormConditionalRule} from '../model/FormConditionalRule';

const Context = createContext<ConditionalRuleContext | null>(null);

interface Props extends PropsWithChildren {
  rule: FormConditionalRule;
}

export const ConditionalRuleProvider = ({rule, children}: Props) => {
  return <Context.Provider value={{rule}}>{children}</Context.Provider>;
};

export interface ConditionalRuleContext {
  rule: FormConditionalRule;
}

export function useConditionalRuleContext(): ConditionalRuleContext {
  const value = useContext(Context);
  if (!value) throw Error('invalid context');
  return value;
}
