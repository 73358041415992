import {Button, Grid} from '@mui/material';
import {useParams} from 'react-router';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FeatureName, Path} from '../../../../paths';
import {TestAttributes} from '../../../TestAttributes';
import {useFormAPI} from '../../../form/FormAPI';
import {FormBuilder} from '../../../form/FormBuilder';
import {onRejectSubmit} from '../../../form/errorHandler';
import {Feature, FeatureBase} from '../../../model/Feature';
import {OnSubmitHandler} from '../../../form/model';
import {FormState} from '../../../form/state/FormState';
import {difference} from '../../../form/utils';
import NotificationService, {NotificationType} from '../../../services/NotificationService';
import {useLoading} from '../../../context/LoadingContext';
import {SysPageInfo, SystemSettingsPages} from '../model/SysPageInfo';
import SystemSettingsPageContainer from '../SystemSettingsPageContainer';
import {useAuth0} from '@auth0/auth0-react';

function FeatureEditPage() {
  const {t} = useTranslation();
  const {id} = useParams();
  const {getAccessTokenSilently} = useAuth0();
  const {setLoading} = useLoading();
  const [feature, setFeature] = useState<Feature | null>(null);
  const formAPI = useFormAPI();

  useEffect(() => {
    if (id) {
      setLoading(true, `FeatureEditPage/GET/${id}`);
      getAccessTokenSilently()
        .then((token) => fetch(`/api/form/v2/admin/features/${id}`, {headers: {authorization: `bearer ${token}`}}))
        .then((response) => response.json().then((data) => ({data, response})))
        .then(({data, response}) => {
          if (response.ok) {
            setFeature(data);
          } else {
            sendError(data.message ?? 'error');
          }
        })
        .finally(() => setLoading(false, `FeatureEditPage/GET/${id}`));
    } else {
      setFeature(null);
    }
  }, [id]);

  useEffect(() => {
    console.log(feature);
  }, [feature]);

  function sendError(error: string) {
    NotificationService.getInstance().sendNotification(error, NotificationType.ERROR);
  }

  const onSubmit: OnSubmitHandler = useCallback(
    (formData: Partial<FeatureBase>, state: FormState) => {
      if (id) {
        const payload = difference(formData, feature);
        setLoading(true, `FeatureEditPage/PATCH/${id}`);
        getAccessTokenSilently()
          .then((token) =>
            fetch(`/api/form/v2/admin/features/${id}`, {
              method: 'PATCH',
              body: JSON.stringify(payload),
              headers: {authorization: `bearer ${token}`},
            })
          )
          .then((response) => response.json().then((data) => ({data, response})))
          .then(({data, response}) => {
            if (response.ok) {
              setFeature(data);
            } else {
              sendError(data.message ?? 'error');
            }
          })
          .finally(() => setLoading(false, `FeatureEditPage/PATCH/${id}`));
      } else {
        const payload = {...formData, url: formData.url ?? ''};
        setLoading(true, `FeatureEditPage/POST`);
        getAccessTokenSilently()
          .then((token) =>
            fetch(`/api/form/v2/admin/features`, {
              method: 'POST',
              body: JSON.stringify(payload),
              headers: {authorization: `bearer ${token}`},
            })
          )
          .then((response) => response.json().then((data) => ({data, response})))
          .then(({data, response}) => {
            if (response.ok) {
              setFeature(data);
            } else {
              sendError(data.message ?? 'error');
            }
          })
          .finally(() => setLoading(false, `FeatureEditPage/POST`));
      }
    },
    [id, feature]
  );

  const page: SysPageInfo = {
    translation: feature?.name ?? id ?? 'new',
    path: `/${Path.SYS_SETTINGS_ROOT}/${Path.SYS_SETTINGS_FEATURES}/${id ?? 'new'}`,
    breadcrumbs: [SystemSettingsPages.ROOT, SystemSettingsPages.FEATURES],
  };

  return (
    <SystemSettingsPageContainer page={page}>
      <Grid container spacing={3} rowSpacing={3}>
        <Grid item>
          <Button {...{[TestAttributes.BUTTON_NAME]: 'save-btn'}} id={'save-btn'} onClick={() => formAPI.submit()}>
            {id ? t('shared.update') : t('shared.save')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <FormBuilder
            onRejectSubmit={onRejectSubmit}
            formId={FeatureName.FEATURES}
            api={formAPI}
            initialValues={feature}
            onSubmit={onSubmit}
          />
        </Grid>
      </Grid>
    </SystemSettingsPageContainer>
  );
}

export default FeatureEditPage;
