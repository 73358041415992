import {Path} from '../../../../paths';

import {SpartaLinkInfo} from './SpartaLinkInfo';

export interface SysPageInfo extends SpartaLinkInfo {
  breadcrumbs?: SpartaLinkInfo[];
}

const ROOT: SysPageInfo = {
  translation: `system-settings.root.headline`,
  path: `/${Path.SYS_SETTINGS_ROOT}`,
};
const ROLE_PERMISSIONS: SysPageInfo = {
  translation: `system-settings.${Path.SYS_SETTINGS_ROLE_PERMISSIONS}.headline`,
  path: `/${Path.SYS_SETTINGS_ROOT}/${Path.SYS_SETTINGS_ROLE_PERMISSIONS}`,
  breadcrumbs: [ROOT],
};
const USER_PERMISSIONS: SysPageInfo = {
  translation: `system-settings.${Path.SYS_SETTINGS_USER_PERMISSIONS}.headline`,
  path: `/${Path.SYS_SETTINGS_ROOT}/${Path.SYS_SETTINGS_USER_PERMISSIONS}`,
  breadcrumbs: [ROOT],
};
const FEATURES: SysPageInfo = {
  translation: `system-settings.${Path.SYS_SETTINGS_FEATURES}.headline`,
  path: `/${Path.SYS_SETTINGS_ROOT}/${Path.SYS_SETTINGS_FEATURES}`,
  breadcrumbs: [ROOT],
};
const CACHE: SysPageInfo = {
  translation: `system-settings.${Path.SYS_SETTINGS_CACHE}.headline`,
  path: `/${Path.SYS_SETTINGS_ROOT}/${Path.SYS_SETTINGS_CACHE}`,
  breadcrumbs: [ROOT],
};
const FORM_EDITOR: SysPageInfo = {
  translation: `Form Editor`,
  path: `/${Path.SYS_SETTINGS_ROOT}/${Path.SYS_SETTINGS_FORM_EDITOR}`,
  breadcrumbs: [ROOT],
};
const FRONTEND_CONFIG: SysPageInfo = {
  translation: `Frontend Config`,
  path: `/${Path.SYS_SETTINGS_ROOT}/${Path.SYS_SETTINGS_FRONTEND_CONFIG}`,
  breadcrumbs: [ROOT],
};

export const SystemSettingsPages = {
  ROOT,
  ROLE_PERMISSIONS,
  USER_PERMISSIONS,
  CACHE,
  FEATURES,
  FORM_EDITOR,
  FRONTEND_CONFIG,
};
