import React, {useEffect, useState} from 'react';
import {useLoading} from '../../../context/LoadingContext';
import {useTranslation} from 'react-i18next';
import SystemSettingsPageContainer from '../SystemSettingsPageContainer';
import {SystemSettingsPages} from '../model/SysPageInfo';
import {Feature} from '../../../model/Feature';
import {useAxiosContext} from '../../../context/AxiosContext';
import {ResponseListWrapper} from '../../../services/model/ResponseListWrapper';
import {FeatureName} from '../../../../paths';
import {useTableColumns} from '../../UseTableColumns';
import {Grid, Skeleton} from '@mui/material';
import SpartanSnackbar from '../../../components/SpartanSnackbar';
import {NotificationType} from '../../../services/NotificationService';
import DataGridCustom from '../../../components/DataGridCustom';
import {formatDateTime} from '../../../utils/DateUtils';
import {TestAttributes} from '../../../TestAttributes';
import {usePermissions, UsePermissionState} from '../../UsePermissions';
import {useLocation} from 'react-router-dom';

function FeaturesPage() {
  const {t} = useTranslation();
  const {setLoading} = useLoading();
  const [features, setFeatures] = useState<Array<Feature>>();
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.FEATURES);

  const {useAxiosPermissionAdminAPI} = useAxiosContext();
  const [{response: getListResponse, loading: isEntityListLoading, error: hasEntityListError}, getAll] =
    useAxiosPermissionAdminAPI<ResponseListWrapper<Feature>>(
      {
        url: `/features`,
        method: 'GET',
        params: {formatted: true},
      },
      {manual: true}
    );

  useEffect(() => {
    getAll();
  }, []);

  useEffect(() => {
    setLoading(isEntityListLoading, 'OrganizationFeatureList');
  }, [isEntityListLoading]);

  useEffect(() => {
    if (getListResponse) {
      setFeatures(getListResponse?.data?.results || []);
    }
  }, [getListResponse]);

  const location = useLocation();
  const currentPagePath = location.pathname.replace(/^\//, '');

  const tableColumns = useTableColumns({
    featureName: FeatureName.FEATURES,
    defaultActionColumn: true,
    actionLink: currentPagePath,
  });

  return (
    <SystemSettingsPageContainer page={SystemSettingsPages.FEATURES}>
      {hasEntityListError && (
        <SpartanSnackbar type={NotificationType.ERROR} message={hasEntityListError.message}></SpartanSnackbar>
      )}
      <Grid container item sx={{height: 'calc(100vh - 250px)'}} {...{[TestAttributes.TABLE_NAME]: 'table-results'}}>
        {isEntityListLoading ? (
          <Skeleton variant="rectangular" height={'100%'} />
        ) : (
          <DataGridCustom
            tableColumns={tableColumns}
            rows={
              features?.map((feature: Feature, index: number) => {
                return {
                  ...feature,
                  created_at: formatDateTime(feature.created_at),
                  last_updated_at: formatDateTime(feature.last_updated_at),
                  actions: feature.feature_id,
                  id: index,
                };
              }) || []
            }
          />
        )}
      </Grid>
    </SystemSettingsPageContainer>
  );
}

export default FeaturesPage;
