import {createContext, PropsWithChildren, useContext} from 'react';
import {useEntities} from '../hooks/useEntities';
import {compareByIndex} from '../model/FormBaseEntity';
import {FormFieldValue} from '../model/FormFieldValue';

type Entity = FormFieldValue;

const Context = createContext<EntitiesContext | null>(null);

interface Props extends PropsWithChildren {
  fieldId: string;
}

export const FieldValuesProvider = ({fieldId, children}: Props) => {
  const {entities, loading, locked, handleCreate, handlePatch, handleDelete} = useEntities<Entity>(
    `fields/${fieldId}/field_values`,
    'fields',
    compareByIndex
  );
  return (
    <Context.Provider value={{fieldValues: entities, loading, locked, handleCreate, handlePatch, handleDelete}}>
      {children}
    </Context.Provider>
  );
};

export interface EntitiesContext {
  fieldValues: Entity[] | null;
  handleCreate: (data: Entity) => Promise<void>;
  handlePatch: (id: string, data: Partial<Entity>) => Promise<void>;
  handleDelete: (id: string) => Promise<void>;
  loading: boolean;
  locked: boolean;
}

export function useFieldValuesContext(): EntitiesContext {
  const value = useContext(Context);
  if (!value) throw Error('invalid context');
  return value;
}
