import {createContext, PropsWithChildren, useContext} from 'react';
import {useEntities} from '../hooks/useEntities';
import {compareByIndex} from '../model/FormBaseEntity';
import {FormMenuItem} from '../model/FormMenuItem';
import {useImportExport} from '../hooks/useImportExport';

type Entity = FormMenuItem;

const Context = createContext<EntitiesContext | null>(null);

interface Props extends PropsWithChildren {
  featureId: string;
  menuItemId?: string;
}

export const MenuItemsProvider = ({menuItemId, featureId, children}: Props) => {
  const collectionPath = menuItemId
    ? `features/${featureId}/menu_items/${menuItemId}/menu_items`
    : `features/${featureId}/menu_items`;
  const {entities, loading, locked, handleCreate, handlePatch, handleDelete} = useEntities<Entity>(
    collectionPath,
    `features/${featureId}/menu_items`,
    compareByIndex
  );
  const {exportEntity, exporting} = useImportExport();
  async function handleExport(id: string) {
    await exportEntity('menu_items', id);
  }
  return (
    <Context.Provider
      value={{
        menuItems: entities,
        loading,
        locked: locked || exporting,
        handleCreate,
        handlePatch,
        handleDelete,
        handleExport,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export interface EntitiesContext {
  menuItems: Entity[] | null;
  handleCreate: (data: Entity) => Promise<void>;
  handlePatch: (id: string, data: Partial<Entity>) => Promise<void>;
  handleDelete: (id: string) => Promise<void>;
  handleExport: (id: string) => Promise<void>;
  loading: boolean;
  locked: boolean;
}

export function useMenuItemsContext(): EntitiesContext {
  const value = useContext(Context);
  if (!value) throw Error('invalid context');
  return value;
}
