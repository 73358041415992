import React from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Grid} from '@mui/material';
import {FormBuilder} from '../../../../../form/FormBuilder';
import {onRejectSubmit} from '../../../../../form/errorHandler';
import schema from './schema/conditional_rule.json';
import {FormSchema} from '../../../../../form/logic/FormSchema';
import {FormState} from '../../../../../form/state/FormState';
import {useNotifications} from '../../hooks/useNodifications';
import {difference} from '../../../../../form/utils';
import {FormConditionalRule} from '../../model/FormConditionalRule';
import {useConditionalRulesContext} from '../../contexts/ConditionalRulesContext';

interface Props {
  rule: FormConditionalRule;
}

export default function ConditionalRuleEditorUpdate({rule}: Props) {
  const {t} = useTranslation();
  const {sendSuccess} = useNotifications();
  const {handlePatch, handleDelete, locked} = useConditionalRulesContext();

  function onSubmit(formData: any, state: FormState) {
    handlePatch(rule.rule_id, difference(formData, rule)).then((data) => sendSuccess(`ConditionalRule updated`));
  }

  function onDelete() {
    handleDelete(rule.rule_id).then(() => sendSuccess(`ConditionalRule deleted`));
  }

  return (
    <Grid container spacing={1} rowSpacing={5}>
      <Grid item xs={12}>
        <FormBuilder
          onRejectSubmit={onRejectSubmit}
          formSchema={schema as FormSchema}
          initialValues={rule}
          onSubmit={onSubmit}
        >
          <Button disabled={locked} type={'submit'}>
            {t('shared.update')}
          </Button>
          <Button disabled={locked} onClick={onDelete} color={'error'}>
            {t('shared.delete')}
          </Button>
        </FormBuilder>
      </Grid>
    </Grid>
  );
}
