import React from 'react';
import {FormField} from '../model/FormField';
import SingleLayoutComponent from './shared/SingleLayoutComponent';
import FieldEditorUpdate from './editors/FieldEditorUpdate';
import ListFieldValueComponent from './ListFieldValueComponent';
import {FieldProvider} from '../contexts/FieldContext';
import ListFieldConditionalRuleComponent from './ListFieldConditionalRuleComponent';

interface Props {
  field: FormField;
}

export default function SingleFieldComponent({field}: Props) {
  const editor = <FieldEditorUpdate field={field} />;
  return (
    <FieldProvider field={field}>
      <SingleLayoutComponent label={field.field_name} editor={editor} disabled={field.disabled}>
        <ListFieldValueComponent fieldId={field.field_id} />
        <ListFieldConditionalRuleComponent fieldId={field.field_id} />
      </SingleLayoutComponent>
    </FieldProvider>
  );
}
