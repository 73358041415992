import {createTheme, ThemeProvider} from '@mui/material/styles';
import {createContext, FunctionComponent, useContext, useMemo} from 'react';
import {baseTypography} from '../../styles/typography';
import {useUserSettingsContext} from './UserSettingsContext';

export const paletteModeDark = 'dark';

// Do not export this, use useColors() instead
const colors = {
  primary: {
    light: '#4f83cc',
    main: '#31599B',
    dark: '#002f6c',
    contrastText: '#ffffff',
  },
  secondary: {
    light: '#9BA9C1',
    main: '#DBE6FA',
    dark: '#B1BFCA',
    contrastText: '#000000',
  },
  error: {
    light: '#f05545',
    main: '#b71c1c',
    dark: '#7f0000',
    contrastText: '#ffffff',
  },
  darkModeColors: {
    darkModeSurfaceTransparency: 'rgb(25,44,76,0.1)',
    darkModeSurface: '#192C4C',
    darkModeSurfaceVariant: '#05080E',
    darkModeHighlightTransparency: 'rgb(75,132,229,0.9)',
    darkModeBase: '#121212',
    darkModeGreyDisabledField: '#24262A',
    darkModeGreyFont: '#9097A3',
    darkModeGreyBorderField: 'rgba(219,230,250,0.3)',
    darkModeGreyBorderFieldHover: 'rgba(219,230,250,0.5)',
  },
  dragAndDropColors: {
    lightGreyColor: 'lightgrey',
    darkGreyOpacityColor: 'rgba(255, 255, 255, 0.16)',
    darkGreyColor: '#767676',
    lightGreyOpacityColor: 'rgba(0, 0, 0, 0.08)',
  },
  default: {
    lightHover: 'rgb(0, 0, 0, 0.04)',
    greyDisabledField: '#eee',
  },
};

const ColorsContext = createContext<any>({});

export function useColors() {
  const context = useContext(ColorsContext);
  if (!context) {
    throw new Error('useColors must be used within LightDarkThemeProvider');
  }
  return context;
}

const LightDarkThemeProvider: FunctionComponent<any> = ({children}) => {
  const {userSettings} = useUserSettingsContext();
  const {theme_palette_mode: mode} = userSettings;

  const theme = useMemo(
    () =>
      createTheme({
        typography: baseTypography,
        palette: {
          mode: mode,
          primary:
            mode === paletteModeDark
              ? {
                  light: colors.primary.light,
                  main: colors.darkModeColors.darkModeHighlightTransparency,
                  dark: colors.darkModeColors.darkModeSurface,
                  contrastText: colors.darkModeColors.darkModeBase,
                }
              : {
                  light: colors.primary.light,
                  main: colors.primary.main,
                  dark: colors.primary.dark,
                  contrastText: colors.primary.contrastText,
                },
          secondary: {
            light: colors.secondary.light,
            main: colors.secondary.main,
            dark: colors.secondary.dark,
            contrastText: colors.secondary.contrastText,
          },
          error: {
            light: colors.error.light,
            main: colors.error.main,
            dark: colors.error.dark,
            contrastText: colors.error.contrastText,
          },
        },
        components: {
          MuiSpeedDialAction: {
            styleOverrides: {
              staticTooltipLabel: {
                minWidth: 'max-content',
              },
            },
          },
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                // Original MuiTooltip backgroundColor: rgba(97, 97, 97, 0.92)
                backgroundColor: mode === paletteModeDark ? 'rgba(97, 97, 97, 0.92)' : 'rgba(153, 153, 153, 0.92)',
              },
            },
          },
          MuiInputBase: {
            defaultProps: {
              size: 'small',
            },
            styleOverrides: {
              root: {
                color: mode === paletteModeDark ? colors.secondary.main : 'text.primary',

                '.Mui-disabled': {
                  backgroundColor:
                    mode === paletteModeDark
                      ? colors.darkModeColors.darkModeGreyDisabledField
                      : colors.default.greyDisabledField,
                  color: mode === paletteModeDark ? colors.darkModeColors.darkModeGreyFont : 'text.primary',
                  borderColor: colors.darkModeColors.darkModeGreyFont,
                },
              },
            },
          },
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor:
                    mode === paletteModeDark ? colors.darkModeColors.darkModeGreyBorderField : 'text.primary',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor:
                    mode === paletteModeDark ? colors.darkModeColors.darkModeGreyBorderFieldHover : 'text.primary',
                  borderWidth: mode === paletteModeDark ? '2px' : '1px',
                },
              },
            },
          },
          MuiToolbar: {
            styleOverrides: {
              root: {
                paddingLeft: '16px !important',
              },
            },
          },
          MuiButton: {
            defaultProps: {
              disableElevation: true,
              size: 'small',
              variant: 'contained',
            },
            styleOverrides: {
              root: {
                textTransform: 'uppercase',
              },
            },
          },
          MuiSvgIcon: {
            styleOverrides: {
              root: {
                color: mode === paletteModeDark ? colors.secondary.main : 'text.primary',
              },
            },
          },
          MuiIconButton: {
            styleOverrides: {
              root: {
                color: mode === paletteModeDark ? colors.secondary.main : 'text.primary',
              },
            },
            variants: [
              {
                props: {color: 'primary'},
                style: {
                  color: colors.primary.main,
                },
              },
              {
                props: {color: 'secondary'},
                style: {
                  color: colors.primary.light,
                },
              },
            ],
          },
          MuiTypography: {
            styleOverrides: {
              root: {
                color: mode === paletteModeDark ? colors.secondary.main : 'text.primary',
              },
            },
          },
          MuiGrid: {
            styleOverrides: {
              root: {
                '.MuiDataGrid-toolbarContainer': {
                  '@media only screen and (max-width: 600px)': {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '.MuiButton-root': {
                      width: '100%',
                      padding: '0px 4px',
                      margin: '4px 4px',
                    },
                  },

                  padding: '8px',
                  '.MuiButton-root': {
                    marginRight: '16px',
                    color: mode === paletteModeDark ? colors.secondary.main : colors.primary.contrastText,
                  },
                },
                '.MuiDataGrid-columnsContainer': {
                  '.MuiDataGrid-menuIcon': {
                    visibility: 'visible',
                  },
                },
                '.MuiDataGrid-row.Mui-even': {
                  backgroundColor:
                    mode === paletteModeDark
                      ? colors.darkModeColors.darkModeHighlightTransparency
                      : colors.secondary.main,
                },
                '.MuiDataGrid-row.Mui-odd:hover': {
                  backgroundColor:
                    mode === paletteModeDark ? colors.darkModeColors.darkModeSurface : colors.default.lightHover,
                },
                '.MuiDataGrid-row.Mui-even:hover': {
                  backgroundColor:
                    mode === paletteModeDark ? colors.darkModeColors.darkModeSurface : colors.default.lightHover,
                },
              },
            },
          },
          MuiFormGroup: {
            defaultProps: {
              row: true,
            },
          },
          MuiPaper: {
            styleOverrides: {
              root: {
                '&.MuiCard-root': {
                  color:
                    mode === paletteModeDark ? colors.secondary.main : (theme: any) => theme.palette.background.default,
                  backgroundColor:
                    mode === paletteModeDark
                      ? colors.darkModeColors.darkModeSurfaceTransparency
                      : (theme: any) => theme.palette.background.default,
                },
                '&.MuiAppBar-root': {
                  backgroundColor:
                    mode === paletteModeDark
                      ? colors.darkModeColors.darkModeSurfaceVariant
                      : (theme: any) => theme.palette.background.default,
                },
                '&.MuiAccordion-root': {
                  backgroundColor:
                    mode === paletteModeDark
                      ? colors.darkModeColors.darkModeSurfaceTransparency
                      : (theme: any) => theme.palette.background.default,
                },
              },
            },
          },
          MuiTab: {
            styleOverrides: {
              root: {
                color: mode === paletteModeDark ? colors.secondary.main : 'text.primary',
                '&.Mui-selected': {
                  backgroundColor: colors.secondary.main,
                },
              },
            },
          },
          MuiFormLabel: {
            styleOverrides: {
              root: {
                fontFamily: 'IBM Plex Sans, sans-serif',
                fontSize: '16px',
                fontWeight: 'normal',
                color: mode === paletteModeDark ? colors.secondary.main : 'text.primary',
              },
            },
          },
          MuiPaginationItem: {
            styleOverrides: {
              root: {
                color: colors.primary.main,
                border: `1px solid ${colors.primary.main}`,
                opacity: '1',
                '&.Mui-selected': {
                  backgroundColor: colors.primary.main,
                  color: colors.primary.contrastText,
                },
                '&.Mui-disabled': {
                  opacity: '1',
                },
              },
            },
          },
          MuiList: {
            styleOverrides: {
              root: {
                color: mode === paletteModeDark ? colors.secondary.main : 'text.primary',
              },
            },
          },
          MuiAppBar: {
            styleOverrides: {
              root: {
                '.MuiInputBase-root': {
                  color: mode === paletteModeDark ? colors.secondary.main : colors.primary.contrastText,
                  border: `1px solid ${mode === paletteModeDark ? colors.secondary.main : colors.primary.contrastText}`,
                },
                '.MuiSelect-icon': {
                  color: mode === paletteModeDark ? colors.secondary.main : colors.primary.contrastText,
                },
              },
            },
          },
          MuiStepLabel: {
            styleOverrides: {
              root: {
                padding: 5,
              },
            },
          },
          MuiBreadcrumbs: {
            styleOverrides: {
              ol: {
                textTransform: 'capitalize',
              },
            },
          },
        },
      }),
    [mode]
  );

  return (
    <ColorsContext.Provider value={colors}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorsContext.Provider>
  );
};

export default LightDarkThemeProvider;
