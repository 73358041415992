import {Grid, Tooltip, useTheme} from '@mui/material';
import React from 'react';
import Button from '@mui/material/Button';
import SystemSettingsPageContainer from './SystemSettingsPageContainer';
import {Path} from '../../../paths';
import {SystemSettingsPages} from './model/SysPageInfo';
import {SpartaLink} from './components/SpartaLink';
import {SpartaLinkInfo} from './model/SpartaLinkInfo';

const PATHS: SpartaLinkInfo[] = [
  {
    path: `/${Path.SYS_SETTINGS_ROOT}/${Path.SYS_SETTINGS_USER_PERMISSIONS}`,
    translation: `system-settings.${Path.SYS_SETTINGS_USER_PERMISSIONS}.headline`,
  },
  {
    path: `/${Path.SYS_SETTINGS_ROOT}/${Path.SYS_SETTINGS_CACHE}`,
    translation: `system-settings.${Path.SYS_SETTINGS_CACHE}.headline`,
  },
  {
    path: `/${Path.SYS_SETTINGS_ROOT}/${Path.SYS_SETTINGS_FORM_EDITOR}`,
    translation: `form-editor`,
  },
  {
    path: `/${Path.SYS_SETTINGS_ROOT}/${Path.SYS_SETTINGS_FRONTEND_CONFIG}`,
    translation: `Frontend Config`,
  },
];

const PATHS_LEGACY: SpartaLinkInfo[] = [
  {
    path: `/${Path.SYS_SETTINGS_ROOT}/${Path.SYS_SETTINGS_ROLE_PERMISSIONS}`,
    translation: `Role Permissions (legacy)`,
  },
  {
    path: `/${Path.SYS_SETTINGS_ROOT}/${Path.SYS_SETTINGS_FEATURES}`,
    translation: `Features (legacy)`,
  },
];

function SystemSettingsRootPage() {
  const theme = useTheme();
  return (
    <SystemSettingsPageContainer page={SystemSettingsPages.ROOT}>
      <Grid container rowSpacing={5}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {PATHS.map((link) => (
              <Grid item key={link.path}>
                <Button color={'secondary'} sx={{padding: '1rem 2rem'}}>
                  <SpartaLink link={link} />
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {PATHS_LEGACY.map((link) => (
              <Grid item key={link.path}>
                <Tooltip title={'Use Form-Editor instead'}>
                  <Button color={'secondary'} sx={{padding: '1rem 2rem', backgroundColor: theme.palette.grey['200']}}>
                    <SpartaLink link={link} />
                  </Button>
                </Tooltip>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </SystemSettingsPageContainer>
  );
}

export default SystemSettingsRootPage;
