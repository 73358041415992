import React from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Grid} from '@mui/material';
import {FormBuilder} from '../../../../../form/FormBuilder';
import {onRejectSubmit} from '../../../../../form/errorHandler';
import schema from './schema/section.json';
import {FormSchema} from '../../../../../form/logic/FormSchema';
import {FormSection} from '../../model/FormSection';
import {FormState} from '../../../../../form/state/FormState';
import {useNotifications} from '../../hooks/useNodifications';
import ResourcePermissionsComponent from '../ResourcePermissionsComponent';
import ExpandableWrapper from '../shared/ExpandableWrapper';
import {useSectionsContext} from '../../contexts/SectionsContext';
import {difference} from '../../../../../form/utils';

interface Props {
  section: FormSection;
}

export default function SectionEditorUpdate({section}: Props) {
  const {t} = useTranslation();
  const {sendSuccess} = useNotifications();
  const {handlePatch, handleDelete, locked} = useSectionsContext();

  function onSubmit(formData: any, state: FormState) {
    handlePatch(section.section_id, difference(formData, section)).then(() => sendSuccess(`Session updated`));
  }

  function onDelete() {
    handleDelete(section.section_id).then(() => sendSuccess(`Session deleted`));
  }

  return (
    <Grid container spacing={1} rowSpacing={5}>
      <Grid item xs={12}>
        <FormBuilder
          onRejectSubmit={onRejectSubmit}
          formSchema={schema as FormSchema}
          initialValues={section}
          onSubmit={onSubmit}
        >
          <Button disabled={locked} type={'submit'}>
            {t('shared.update')}
          </Button>
          <Button disabled={locked} onClick={onDelete} color={'error'}>
            {t('shared.delete')}
          </Button>
        </FormBuilder>
      </Grid>
      <Grid item xs={12}>
        <ExpandableWrapper label={'Permissions'}>
          <ResourcePermissionsComponent resourceId={section.section_id} />
        </ExpandableWrapper>
      </Grid>
    </Grid>
  );
}
