import React from 'react';
import {ConditionalRulesProvider, useConditionalRulesContext} from '../contexts/ConditionalRulesContext';
import ListLayoutComponent from './shared/ListLayoutComponent';
import ConditionalRuleEditorCreate from './editors/ConditionalRuleEditorCreate';
import SingleConditionalRuleComponent from './SingleConditionalRuleComponent';

interface Props {
  sectionId: string;
}

function InternalConditionalRulesComponent({sectionId}: Props) {
  const {rules, loading} = useConditionalRulesContext();
  const editor = <ConditionalRuleEditorCreate partial={{resource_id: sectionId, resource_type: 'section'}} />;
  const children = rules?.map((rule) => <SingleConditionalRuleComponent rule={rule} key={rule.rule_id} />);
  return <ListLayoutComponent title={'Conditional Rules'} children={children} editor={editor} loading={loading} />;
}

export default function ListSectionConditionalRuleComponent({sectionId}: Props) {
  return (
    <ConditionalRulesProvider resourceId={sectionId}>
      <InternalConditionalRulesComponent sectionId={sectionId} />
    </ConditionalRulesProvider>
  );
}
