import {createContext, PropsWithChildren, useContext} from 'react';
import {useEntities} from '../hooks/useEntities';
import {compareByName, FormFeature} from '../model/FormFeature';
import {useImportExport} from '../hooks/useImportExport';

type Entity = FormFeature;

const Context = createContext<EntitiesContext | null>(null);

interface Props extends PropsWithChildren {}

export const FeaturesProvider = ({children}: Props) => {
  const {entities, loading, locked, handleGet, handleCreate, handlePatch, handleDelete} = useEntities<Entity>(
    'features',
    'features',
    compareByName
  );
  const {exportEntity, exporting} = useImportExport();
  async function handleExport(id: string) {
    await exportEntity('features', id);
  }
  return (
    <Context.Provider
      value={{
        features: entities,
        loading,
        locked: locked || exporting,
        handleGet,
        handleCreate,
        handlePatch,
        handleDelete,
        handleExport,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export interface EntitiesContext {
  features: Entity[] | null;
  handleGet: () => Promise<void>;
  handleCreate: (data: Entity) => Promise<void>;
  handlePatch: (id: string, data: Partial<Entity>) => Promise<void>;
  handleDelete: (id: string) => Promise<void>;
  handleExport: (id: string) => Promise<void>;
  loading: boolean;
  locked: boolean;
}

export function useFeaturesContext(): EntitiesContext {
  const value = useContext(Context);
  if (!value) throw Error('invalid context');
  return value;
}
