import {createContext, PropsWithChildren, useContext} from 'react';
import {compareByIndex} from '../model/FormBaseEntity';
import {useEntities} from '../hooks/useEntities';
import {FormConditionalRule} from '../model/FormConditionalRule';

type Entity = FormConditionalRule;

const Context = createContext<EntitiesContext | null>(null);

interface Props extends PropsWithChildren {
  resourceId: string;
}

export const ConditionalRulesProvider = ({resourceId, children}: Props) => {
  const {entities, loading, locked, handleCreate, handlePatch, handleDelete} = useEntities<Entity>(
    `conditional_rules/by_resource_id/${resourceId}`,
    `conditional_rules`,
    compareByIndex
  );
  return (
    <Context.Provider value={{rules: entities, loading, locked, handleCreate, handlePatch, handleDelete}}>
      {children}
    </Context.Provider>
  );
};

export interface EntitiesContext {
  rules: Entity[] | null;
  handleCreate: (data: Entity) => Promise<void>;
  handlePatch: (id: string, data: Partial<Entity>) => Promise<void>;
  handleDelete: (id: string) => Promise<void>;
  loading: boolean;
  locked: boolean;
}

export function useConditionalRulesContext(): EntitiesContext {
  const value = useContext(Context);
  if (!value) throw Error('invalid context');
  return value;
}
