import React from 'react';
import {useTranslation} from 'react-i18next';
import {Button, CircularProgress, Grid} from '@mui/material';
import {FormBuilder} from '../../../../../form/FormBuilder';
import {onRejectSubmit} from '../../../../../form/errorHandler';
import schema from './schema/feature.json';
import {FormSchema} from '../../../../../form/logic/FormSchema';
import {FormFeature} from '../../model/FormFeature';
import {useNotifications} from '../../hooks/useNodifications';
import {FormState} from '../../../../../form/state/FormState';
import {useFeaturesContext} from '../../contexts/FeaturesContext';
import {difference} from '../../../../../form/utils';
import ResourcePermissionsComponent from '../ResourcePermissionsComponent';
import ExpandableWrapper from '../shared/ExpandableWrapper';

interface Props {
  feature: FormFeature;
}

export default function FeatureEditorUpdate({feature}: Props) {
  const {t} = useTranslation();
  const {sendSuccess} = useNotifications();
  const {handlePatch, handleDelete, handleExport, locked} = useFeaturesContext();

  function onSubmit(formData: any, state: FormState) {
    handlePatch(feature.feature_id, difference(formData, feature)).then((data) => sendSuccess(`Feature updated`));
  }

  function onDelete() {
    handleDelete(feature.feature_id).then(() => sendSuccess(`Feature deleted`));
  }

  function onExport() {
    handleExport(feature.feature_id).then(() => sendSuccess(`Export completed`));
  }

  return (
    <Grid container spacing={1} rowSpacing={5}>
      <Grid item xs={12}>
        <FormBuilder
          onRejectSubmit={onRejectSubmit}
          formSchema={schema as FormSchema}
          initialValues={feature}
          onSubmit={onSubmit}
        >
          <Button disabled={locked} type={'submit'}>
            {t('shared.update')}
          </Button>
          <Button disabled={locked} onClick={onDelete} color={'error'}>
            {t('shared.delete')}
          </Button>
          <Button disabled={locked} onClick={onExport} color={'secondary'}>
            {t('shared.export')}
          </Button>
          {locked && <CircularProgress size={30} sx={{marginLeft: 1}} />}
        </FormBuilder>
      </Grid>
      <Grid item xs={12}>
        <ExpandableWrapper label={'Permissions'}>
          <ResourcePermissionsComponent resourceId={feature.feature_id} />
        </ExpandableWrapper>
      </Grid>
    </Grid>
  );
}
