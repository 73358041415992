import {useCallback, useState} from 'react';
import {useFetch} from './useFetch';
import {useConfig} from '../../../../context/ConfigContext';
import {useImportContext} from '../contexts/ImportContext';

export interface UseImportExport {
  exportEntity: (name: string, id: string) => Promise<Blob>;
  exporting: boolean;
  importEntity: (file: File) => Promise<void>;
  importing: boolean;
}

export function useImportExport(): UseImportExport {
  const {send} = useFetch();
  const {onCompleted} = useImportContext();
  const {SPANNER_API_PATH} = useConfig();
  const [importing, setImporting] = useState(false);
  const [exporting, setExporting] = useState(false);

  const exportEntity = useCallback(async (name: string, id: string) => {
    setExporting(true);
    try {
      const data = await send('POST', `${SPANNER_API_PATH}/export/${name}/${id}`);
      const file = new Blob([JSON.stringify(data, null, 2)], {type: 'text/plain'}); //pass data from localStorage API to blob
      const element = document.createElement('a');
      element.href = URL.createObjectURL(file);
      element.download = `export-${name}-${id}.json`;
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      return file;
    } finally {
      setExporting(false);
    }
  }, []);

  const importEntity = useCallback(async (file: File) => {
    setImporting(true);
    try {
      const data = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          resolve(JSON.parse(reader.result as string));
        };
        reader.readAsText(file);
      });
      await send('POST', `${SPANNER_API_PATH}/import`, data);
      onCompleted();
    } finally {
      setImporting(false);
    }
  }, []);

  return {exportEntity, exporting, importEntity, importing};
}
