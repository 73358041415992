import {createContext, PropsWithChildren, useContext} from 'react';
import {useEntities} from '../hooks/useEntities';
import {compareByIndex} from '../model/FormBaseEntity';
import {FormSection} from '../model/FormSection';

type Entity = FormSection;

const Context = createContext<EntitiesContext | null>(null);

interface Props extends PropsWithChildren {
  sectionId?: string;
  featureId?: string;
}

export const SectionsProvider = ({sectionId, featureId, children}: Props) => {
  const collectionPath = sectionId
    ? `sections/${sectionId}/sections`
    : featureId
    ? `features/${featureId}/sections`
    : undefined;
  if (!collectionPath) throw Error('you must provide either sectionId or featureId');
  const {entities, loading, locked, handleCreate, handlePatch, handleDelete} = useEntities<Entity>(
    collectionPath,
    'sections',
    compareByIndex
  );
  return (
    <Context.Provider value={{sections: entities, loading, locked, handleCreate, handlePatch, handleDelete}}>
      {children}
    </Context.Provider>
  );
};

export interface EntitiesContext {
  sections: Entity[] | null;
  handleCreate: (data: Entity) => Promise<void>;
  handlePatch: (id: string, data: Partial<Entity>) => Promise<void>;
  handleDelete: (id: string) => Promise<void>;
  loading: boolean;
  locked: boolean;
}

export function useSectionsContext(): EntitiesContext {
  const value = useContext(Context);
  if (!value) throw Error('invalid context');
  return value;
}
