import {useCallback} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {useNotifications} from './useNodifications';

export interface UseFetch {
  send: (method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE', url: string, body?: any | null) => Promise<any>;
}

export function useFetch(): UseFetch {
  const {getAccessTokenSilently} = useAuth0();
  const {sendError} = useNotifications();

  const send = useCallback(
    async (method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE', url: string, body?: any | null): Promise<any> => {
      return getAccessTokenSilently()
        .then((token) =>
          fetch(url, {
            method,
            body: body ? JSON.stringify(body) : undefined,
            headers: {authorization: `bearer ${token}`, 'content-type': 'application/json'},
          })
        )
        .then((response) => {
          if (response.status === 204) {
            return {data: null, response};
          } else {
            return response.json().then((data) => ({data, response}));
          }
        })
        .then(({data, response}) => {
          if (response.ok) {
            return data;
          } else {
            sendError(data?.error_data?.message ?? data?.message ?? 'error');
            throw Error(JSON.stringify(data));
          }
        });
    },
    []
  );

  return {send};
}
