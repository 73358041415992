import {useCallback, useEffect, useState} from 'react';
import {useFormEditorFetch} from './useFormEditorFetch';
import {useNotifications} from './useNodifications';
import {useImportContext} from '../contexts/ImportContext';

export interface UseEntities<T> {
  entities: T[] | null;
  loading: boolean;
  locked: boolean;
  handleGet: () => Promise<void>;
  handleCreate: (data: T) => Promise<void>;
  handlePatch: (id: string, data: Partial<T>) => Promise<void>;
  handleDelete: (id: string) => Promise<void>;
}

export function useEntities<T>(
  collectionPath: string,
  entityPath: string,
  compareFn: (a: T, b: T) => number = (a, b) => 0
): UseEntities<T> {
  const {completed} = useImportContext();
  const {get, post, patch, sendDelete} = useFormEditorFetch();
  const [loading, setLoading] = useState(false);
  const [locked, setLocked] = useState(false);
  const [entities, setEntities] = useState<T[] | null>(null);
  const {sendError} = useNotifications();

  const handleGet = useCallback(async () => {
    setLoading(true);
    return get(collectionPath)
      .then((data) => setEntities(data.results?.sort(compareFn)))
      .catch((e) => sendError(e.message))
      .finally(() => setLoading(false));
  }, [collectionPath]);

  const handleCreate = useCallback(
    async (data: T) => {
      setLocked(true);
      return post(entityPath, data)
        .then(() => handleGet())
        .finally(() => setLocked(false));
    },
    [entityPath]
  );

  const handlePatch = useCallback(
    async (id: string, data: Partial<T>) => {
      setLocked(true);
      return patch(`${entityPath}/${id}`, data)
        .then(() => handleGet())
        .finally(() => setLocked(false));
    },
    [entities, entityPath]
  );

  const handleDelete = useCallback(
    async (id: string) => {
      setLocked(true);
      return sendDelete(`${entityPath}/${id}`)
        .then(() => handleGet())
        .finally(() => setLocked(false));
    },
    [entityPath]
  );

  useEffect(() => {
    handleGet().then();
  }, [handleGet, completed]);

  return {entities, loading, locked, handleGet, handleCreate, handlePatch, handleDelete};
}
